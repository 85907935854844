import styles from "./DayCard.module.css";

const DayCard = (props) => {
  const slicedText = props.text.slice(0, 100);
  const slicedOnWord = slicedText.slice(0, slicedText.lastIndexOf(" "));
  const image = props.image
    ? require(`../assets/images/${props.index}/${props.image}`).default
    : require(`../assets/images/background/desert.jpg`).default;

  return (
    <div className={styles.card}>
      <header
        style={{
          backgroundImage: `url(${image})`,
        }}
        id={props.image}
        className={styles["card-header"]}
      >
        <h4 className={styles["card-header--title"]}>Dag {props.index + 1}</h4>
      </header>
      <div className={styles["card-body"]}>
        <p className={styles.date}>{props.date} 2017</p>
        <div className={styles.title}>{props.title}</div>

        <p className={styles["body-content"]}>{slicedOnWord}...</p>
        <button className={`${styles.button} ${styles["button-primary"]}`}>
          <i
            className={`${styles["fa-chevron-right"]} fa fa-chevron-right`}
          ></i>
          Lees verder
        </button>
      </div>
    </div>
  );
};

export default DayCard;
