const themeUSA = {
     backgroundColor: "rgb(141, 176, 185)",
     color: "#8d5b46",
     border: "1px solid #8d5b46" 
}

const themeRSA = {
    backgroundColor: "rgb(0, 122, 77)",
    color: "#ffb612",
    border: "1px solid #ffb612"
}

export const themes = {
    themeUSA: themeUSA,
    themeRSA: themeRSA,
}