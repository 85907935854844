import styles from "./Home.module.css";

const Home = (props) => {
  return (
    <div className={styles.homeText}>
      {props.eventState === "USA" && (
        <>
          <div>USA Roadtrip</div>
          <button onClick={props.onEventChange}>Change to South Africa</button>
        </>
      )}
      {props.eventState === "RSA" && (
        <>
          <div>South Africa Roadtrip</div>
          <button onClick={props.onEventChange}>Change to USA</button>
        </>
      )}
    </div>
  );
};

export default Home;
