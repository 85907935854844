import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";

import firebase from "firebase/app";
import "firebase/database";
import "./utils/firebaseconfig";

import NavBar from "./components/NavBar";
import Day from "./components/Day";
import Home from "./pages/Home";
import Fotos from "./pages/Fotos";
import Days from "./pages/Days";
import Admin from "./pages/Admin";
import Modal from "./UI/Modal";

import styles from "./App.module.css";
import {themes} from "./assets/globals/themes";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [currentDay, setCurrentDay] = useState(0);
  const [dayModalShown, setDayModalShown] = useState(false);
  const [eventState, setEventState] = useState("USA");
  const [theme, setTheme] = useState(themes.themeUSA)

  const backgroundImage = blogData.background
    ? require(`./assets/images/background/${blogData.background}`).default
    : require(`./assets/images/background/desert.jpg`).default;

  const onEventChange = () => {
    setEventState((prevState) => {
      if (prevState === "USA") {
        setTheme(themes.themeRSA);
        return "RSA";
      } else {
        setTheme(themes.themeUSA);
        return "USA";
      }
    });
  };

  const fetchDataHandler = useCallback(() => {
    setIsLoading(true);
    const blogDataRef = firebase.database().ref(`${eventState}/`);
    blogDataRef.on("value", (snapshot) => {
      const data = snapshot.val();
      setBlogData(data);
    });
    setIsLoading(false);
  }, [eventState]);

  const onCurrentDayChange = (num) => {
    setCurrentDay(num);
    if (!dayModalShown) {
      setDayModalShown(true);
      document.body.style.overflow = "hidden";
    }
  };

  const onCloseModal = () => {
    setDayModalShown(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    fetchDataHandler();
  }, [fetchDataHandler]);

  return (
    <div
      className={styles.App}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <Router>
        <NavBar
          onCloseModal={onCloseModal}
          showModal={dayModalShown}
          eventState={eventState}
          theme={theme}
        />
        {dayModalShown && (
          <Modal
            onClose={onCloseModal}
            onChangeDay={onCurrentDayChange}
            currentDay={currentDay}
            lastDay={blogData.days.length - 1}
          >
            <Day
              currentDay={currentDay}
              selectedDay={blogData.days[currentDay]}
            />
          </Modal>
        )}
        <div className={styles.pages}>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Home
                  {...props}
                  onEventChange={onEventChange}
                  eventState={eventState}
                />
              )}
            />
            <Route
              exact
              path="/days"
              render={(props) => (
                <Days
                  {...props}
                  onClickCard={onCurrentDayChange}
                  blogData={blogData.days}
                  showModal={dayModalShown}
                  currentDay={currentDay}
                  isLoading={isLoading}
                />
              )}
            />
            <Route exact path="/fotos" component={Fotos} />
            <Route
              exact
              path="/admin"
              render={(props) => <Admin {...props} />}
            />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
