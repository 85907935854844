import firebase from "firebase";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCRzL21o6w98AWLF4-IqTGtPnr3qjHro-U",
    authDomain: "blog-ed866.firebaseapp.com",
    databaseURL: "https://blog-ed866-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "blog-ed866",
    storageBucket: "blog-ed866.appspot.com",
    messagingSenderId: "1021493023106",
    appId:"1:1021493023106:web:cca0b622ea369c57747f9b",
    measurementId: "G-G7JQJGCWL6",
};

firebase.initializeApp(firebaseConfig);
const database = firebase.firestore();

export default database;