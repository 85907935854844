import Card from "../UI/Card";
import { useState, useRef } from "react";

const Admin = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [segmentsNumber, setSegmentsNumber] = useState(0);

  const titleRef = useRef();
  const dateRef = useRef();
  const textRefs = useRef([]);
  const imageRefs = useRef([]);
  const panoRefs = useRef([]);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    alert("Add submit");
  };

  const onAddSectionHandler = (e) => {
    setSegmentsNumber(segmentsNumber + 1);
  };

  const onDeleteSectionHandler = (e) => {
    if (segmentsNumber !== 0) {
      setSegmentsNumber(segmentsNumber - 1);
    }
  };

  const loginHandler = () => {
    setIsLoggedIn((prevState) => !prevState);
    console.log(isLoggedIn);
  };

  return (
    <Card>
      {!isLoggedIn && (
        <>
          <h1>Please Log In</h1>
          <button onClick={loginHandler}>Log In</button>
        </>
      )}
      {isLoggedIn && (
        <>
          {" "}
          <form onSubmit={handleOnSubmit}>
            <label>title</label>
            <br />
            <input ref={titleRef} type="text"></input>
            <label>date</label>
            <input ref={dateRef} type="date"></input>
            {[...Array(segmentsNumber)].map(
              (value: undefined, index: number) => (
                <section
                  key={index}
                  style={{
                    backgroundColor: `#${Math.random()
                      .toString(16)
                      .substr(-6)}`,
                  }}
                >
                  <label>text</label>
                  <input
                    ref={(el) => (textRefs.current[index] = el)}
                    type="text"
                  ></input>
                  <label>Image</label>
                  <input
                    ref={(el) => (imageRefs.current[index] = el)}
                    type="file"
                    id="img"
                    name="img"
                    accept="image/*"
                  />
                  <label>Pano url</label>
                  <input
                    ref={(el) => (panoRefs.current[index] = el)}
                    type="text"
                  ></input>
                </section>
              )
            )}
            {segmentsNumber !== 0 && <button>Submit</button>}
          </form>
          <button onClick={onAddSectionHandler}>Add Segment</button>
          <button onClick={onDeleteSectionHandler}>Delete segment</button>
          <button onClick={loginHandler}>Log Out</button>
        </>
      )}
    </Card>
  );
};

export default Admin;
