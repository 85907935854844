import styles from "./NavBar.module.css";
import {themes} from "../assets/globals/themes";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

const NavBar = (props) => {
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(false);

  const BackgroundUSA = {
    color: "rgb(141, 176, 185)",
}

  const navControl = () => {
    if (window.scrollY > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", navControl);
    return () => window.removeEventListener("scroll", navControl);
  }, []);

  const handleClick = () => {
    setClick((prevState) => !prevState);
    props.onCloseModal();
  };
  return (
    <nav
      className={styles.navbar}
      style={scroll && !props.showModal ? props.theme : {}}
    >
      <div className={styles["nav-container"]}>
        <NavLink
          exact
          to="/"
          activeClassName={styles.active}
          style={{color: props.theme.color}}
          className={styles["nav-logo"]}
          onClick={handleClick}
        >
          Marieke&Sebas
          <i className={`${styles["fa-code"]} fas fa-globe`}></i>
        </NavLink>
        <ul
          className={`${styles["nav-menu"]} ${click ? styles["active"] : ""}`}
        >
          <li className={styles["nav-item"]}>
            <NavLink
              exact
              to="/"
              activeClassName={styles.active}
              className={styles["nav-links"]}
              style={{color: props.theme.color}}
              onClick={handleClick}
            >
              {props.eventState}
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              exact
              to="/days"
              activeClassName={styles.active}
              className={styles["nav-links"]}
              style={{color: props.theme.color}}
              onClick={handleClick}
            >
              Days
            </NavLink>
          </li>
          <li className={styles["nav-item"]}>
            <NavLink
              exact
              to="/admin"
              activeClassName={styles.active}
              className={styles["nav-links"]}
              style={{color: props.theme.color}}
              onClick={handleClick}
            >
              Admin
            </NavLink>
          </li>
        </ul>
        <div className={styles["nav-icon"]}>
          <i
            className={`${styles["fa-times"]} ${
              click ? "fas fa-times" : "fas fa-bars"
            }`}
            onClick={handleClick}
          ></i>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
