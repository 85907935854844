import styles from "./Day.module.css";
import Card from "../UI/Card";

const Day = (props) => {
  return (
    <Card className={styles.Card}>
      <div id="dayContent" className={styles.cardBody}>
        <div className={styles.cardDayNumber}>
          {`Dag ${props.currentDay + 1}`}
        </div>
        <div className={styles.cardTitle}>{props.selectedDay.title}</div>
        <div className={styles.cardDate}>{props.selectedDay.date}</div>

        {props.selectedDay.segments &&
          props.selectedDay.segments.map((segment, idx) => {
            return (
              <div key={idx}>
                {segment.text && (
                  <p className={styles.cardText}>{segment.text}</p>
                )}
                {segment.images &&
                  segment.images.map((image, idx) => (
                    <img
                      alt={`${image}`}
                      className={styles.cardImg}
                      key={idx}
                      src={
                        require(`../assets/images/${props.currentDay}/${image}`)
                          .default
                      }
                    />
                  ))}
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default Day;

// {segment.panos &&
//   segment.panos.map((pano, index) => (
//     <iframe
//       key={index}
//       title={pano}
//       src={pano}
//       frameborder="0"
//       margin="auto"
//       text-align="center"
//       width="100%"
//       height={315}
//       allowfullscreen
//       data-token="io4N8X"
//     ></iframe>
//   ))}
