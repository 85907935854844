import { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const dayBack = () => {
    if (props.currentDay !== 0) {
      props.onChangeDay(props.currentDay - 1);
      document.getElementById("dayContent").scrollTop = 0;
    }
  };
  const dayForward = () => {
    if (props.currentDay !== props.lastDay) {
      props.onChangeDay(props.currentDay + 1);
      document.getElementById("dayContent").scrollTop = 0;
    }
  };
  return (
    <div className={styles.modal}>
      {props.currentDay !== 0 && (
        <button onClick={dayBack} className={styles.buttonLeft}>
          Dag {props.currentDay}
          <br />
          <i
            className={`${styles["fa-arrow"]} far fa-arrow-alt-circle-left`}
          ></i>
        </button>
      )}
      <div className={styles.content}>{props.children}</div>
      {props.currentDay !== props.lastDay && (
        <button onClick={dayForward} className={styles.buttonRight}>
          Dag {props.currentDay + 2}
          <br />
          <i
            className={`${styles["fa-arrow"]} far fa-arrow-alt-circle-right`}
          ></i>
        </button>
      )}
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onChangeDay={props.onChangeDay}
          currentDay={props.currentDay}
          lastDay={props.lastDay}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
