import DayCard from "../components/DayCard";
//import { useHistory } from "react-router-dom";
import styles from "./Days.module.css";
import Card from "../UI/Card";

const Days = (props) => {
  const handleClick = (index) => {
    props.onClickCard(index);
  };
  return (
    <Card className={styles.Card}>
      {!props.showModal && props.isLoading && <h1>Loading...</h1>}
      {!props.showModal && !props.isLoading && (
        <section className={styles["app-card-list"]} id="app-card-list">
          {props.blogData &&
            props.blogData.map((item, index) => {
              if (item.title) {
                return (
                  <div
                    onClick={() => handleClick(index)}
                    className={styles["app-card"]}
                    key={index}
                  >
                    <DayCard
                      index={index}
                      title={item.title}
                      image={item.thumbnail}
                      text={item.segments ? item.segments[0].text : ""}
                      date={item.date}
                    />
                  </div>
                );
              }
              return null;
            })}
        </section>
      )}
    </Card>
  );
};

export default Days;
